<template>
  <el-container>
    <header class="page-header">
      <el-header class="header-menu">
        <head-bar :showCollapse="false" :activeValue="active"></head-bar>
      </el-header>
      <div class="content-header">
        <el-form :inline="true" size="small" label-width="80px">
          <el-form-item label="审核状态">
            <el-select clearable v-model="search.searchFields.check_status" placeholder="请选择">
              <el-option
                v-for="item in check_status_list"
                :key="item.code"
                :label="item.value"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手机号" required >
            <el-input clearable v-model="search.searchFields.mobile" placeholder="请输入手机号"></el-input>
          </el-form-item>
        </el-form>
        <div class="search-button-container">
          <el-button class="search-button" type="primary" icon="el-icon-search" size="small" @click="getBaList(1)">查询</el-button>
          <el-button size="small" @click="clearSearch" plain>重置</el-button>
          <el-button type="primary" size="small" @click="handleBatch('refuse')">批量拒绝</el-button>
          <el-button type="primary" size="small" @click="handleBatch('pass')">批量通过</el-button>
        </div>
      </div>
    </header>
    <el-container>
      <div class="content">
        <div class="main" ref="main">
          <el-checkbox-group v-model="baIdList">
            <el-card class="item" v-for="(item, index) of list" :key="item.id" :ref="`item${item.id}`">
              <el-descriptions :column="2" size="small">
                <el-checkbox slot="title" :label="item.id">{{ item.name }}</el-checkbox>
                <el-descriptions-item label="手机号">{{item.mobile}}</el-descriptions-item>
                <el-descriptions-item label="工号">{{item.job_number}}</el-descriptions-item>
                <el-descriptions-item label="门店编号">{{item.shop_number}}</el-descriptions-item>
                <el-descriptions-item label="状态">{{checkStatus(item.check_status)}}</el-descriptions-item>
                <el-descriptions-item label="注册时间">{{formatDate(Number(item.create_time) * 1000, 'YYYY.MM.DD').dateString}}</el-descriptions-item>
                <el-descriptions-item label="店铺名称">{{item.shop_name}}</el-descriptions-item>
              </el-descriptions>
            </el-card>
          </el-checkbox-group>
        </div>
      </div>
    </el-container>
  </el-container>
</template>
<script>
import headBar from '@/components/frames/mobileHeadBar'
import {throttle, formatDate} from '@/libs/utils'
import { BAApi } from '@/api'

export default {
  data: () => ({
    search: {
      searchFields: {
        page: 1,
        count: 20,
        check_status: '',
        mobile: '',
        page: 1,
        count: 20,
        myList: [],
        hasMore: true,
        noData: false
      }
    },
    list: [],
    active: 'mobileBa',
    check_status_list: [
      {
        code: 0,
        value: '全部'
      },
      {
        code: 1,
        value: '审核中'
      },
      {
        code: 2,
        value: '审核通过'
      },
      {
        code: 3,
        value: '审核拒绝'
      }
    ],
    baIdList: []
  }),
  components: {
    headBar
  },
  methods: {
    formatDate: formatDate,
    checkStatus: value => {
      switch (Number(value)) {
        case 1:
          return '待审核'
        case 2:
          return '审核通过'
        case 3:
          return '审核作废'
        case 4:
          return '审核拒绝'
        default:
          break
      }
    },
    clearSearch() {
      this.search = {
        searchFields: Object.assign({ count: this.search.searchFields.count }, {
          page: 1,
          check_status: '',
          mobile: '',
        })
      }
    },
    // get data
    async getBaList(page) {
      this.search.searchFields.page = page || this.search.searchFields.page
      let params = {...this.search.searchFields}
      try {
        let data = (await BAApi.getBaList(params)).data
        if (data.code === 0) {
          this.$validator.reset()
          if (this.search.searchFields.page === 1) {
            this.search.searchFields.myList = []
          }
          const newList = data.data.apply_list || []
          if (newList.length >= this.search.searchFields.count) {
            this.search.searchFields.hasMore = true
          } else {
            this.search.searchFields.hasMore = false
          }
          this.search.searchFields.myList = this.search.searchFields.myList.concat(newList)
          if (this.search.searchFields.myList.length === 0 && this.search.searchFields.page === 1) {
            this.search.searchFields.noData = true
          } else {
            this.search.searchFields.noData = false
          }
          this.list = this.search.searchFields.myList
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      }
    },
    handleBatch(type) {
      let data = this.baIdList
      if (data.length > 0) {
        let params = {
          ids: data.join(','),
          check_status: type === 'pass' ? 2 : 3
        }
        let tip = `确定全部审核${type !== 'pass' ? '不':''}通过？`
        return this.$confirm(tip, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.changeStatus(params)
          })
      } else {
        this.$message.error('请先进行选择')
        return
      }
    },
    async changeStatus(params) {
      let loading = this.$loading()
      try {
        let data = (await BAApi.updateStatus(params)).data
        if (data.code === 0) {
          this.$message.success('审核成功')
          this.getBaList()
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        loading.close()
      }
    },
  },

  mounted() {
    window.scrollTo(0, 1)
    this.getBaList()
    let that = this
    window.onscroll = throttle(function () {
      const scrollHeight = document.body.scrollHeight
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      const offsetHeight = document.body.offsetHeight
      if (scrollHeight - (scrollTop + offsetHeight) <= 150 && that.search.searchFields.hasMore) {
        that.search.searchFields.page ++
        that.getBaList()
      }
    }, 1000, true)
  },
  beforeDestroy() {
    window.onscroll = null
  }
}
</script>

<style lang="less" scoped>
.page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 9;
  .content-header {
    margin-top: 15px;
    overflow: hidden;
    .search-button-container {
      float: right;
      padding-right: 15px;
      .search-button {
        margin-left: 15px;
      }
    }
  }
  .header-menu {
    height: auto!important;
  }
}
.content {
  margin-top: 270px;
  .button-field {
    padding-left: 15px;
  }
  .main {
    padding: 15px;
    .item {
      margin-bottom: 15px;
      .foot {
        height: 25px;
        padding-top: 10px;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        color: #409EFF;
      }
    }
  }
}
.content-header /deep/ .el-select {
  width: 260px;
}
.content-header /deep/ .el-input {
  width: 260px;
}
.el-menu-demo {
  overflow-x: scroll;
  display: flex;
}
</style>
<style lang="less">
.el-message-box {
  width: 360px;
}
</style>


